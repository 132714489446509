export enum FilterEnum {
  Status = 'status',
  Profile = 'profileId',
  Category = 'catalogId',
  ClientClass = 'clientClassId',
  Sector = 'sectorId',
  ClientCategory = 'clientCategoryId',
  Zone = 'zoneId',
  Paginator = 'paginator',
  ProductType = 'type',
  ProductPriceType = 'priceType',
  ProductCatalog = 'catalog',
  ProductBrand = 'brand',
  ClassId = 'classId',
  CategoryId = 'categoryId',
  ClientId = 'clientId',
  Date = 'date',
  AgentId = 'agentId',
  UserId = 'userId',
  OriginOrderId = 'originOrderId',
  HasSupport = 'hasSupport',
  HasDiscount = 'hasDiscount',
  ZoneChief = 'zoneChiefId',
  WarehouseId = 'warehouseId',
  SalesLineId = 'salesLineId',
  ClientStatus = 'clientStatus',
  ActivationStatus = 'activationStatus',
  Expand = 'expand',
  DealStates = 'dealStates',
  Diffs = 'diffs',
  ExcludedDiscounts = 'excludedDiscountIds',
}
