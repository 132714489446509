export const tabs = {
  product_info: 'Information Produit',
  product_Package: 'Stock et colisage',
  product_Prices: 'Gestion des prix',
  product_media: 'Médias',
  promo_cible: 'Offre et cible',
  promo_code: 'Code',
  promo_manager_price: 'Gestion des prix',
  cible: 'Cible',
  promo_manager: 'Gestion des promotions',
  summary: 'Résumé',
  media_description: 'Description et médias',
  discount: 'Remise',
  bundle_conditionalBundle: 'Bundle / Bundle conditionné',
};
