import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { ICustomDateTimePickerProps } from './CustomDateTimePicker.type';
import dayjs from 'dayjs';
import { GlobalVariables } from 'config/constant';
import { translate } from 'locales/i18n';
import { Stack, Typography } from '@mui/material';

function CustomDateTimePicker({
  value,
  onChange,
  error,
  label,
  required,
  name,
}: ICustomDateTimePickerProps) {
  return (
    <Stack spacing={1.5}>
      {label && <Typography variant="h5">{translate(label)}</Typography>}
      <LocalizationProvider
        helperText={translate(error?.message || GlobalVariables.EmptyString)}
        dateAdapter={AdapterDayjs}
        error={!!error}
        required={required}
        name={name}
      >
        <DateTimePicker
          ampm={false}
          value={value ? dayjs(value as string) : null}
          onChange={onChange}
          disablePast
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          slotProps={{
            textField: {
              helperText: translate(error?.message || GlobalVariables.EmptyString),
              error: !!error,
            },
          }}
        />
      </LocalizationProvider>
    </Stack>
  );
}
export default CustomDateTimePicker;
