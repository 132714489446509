import { Stack, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GlobalVariables } from 'config/constant';
import { CustomDatePickerProps } from './CustomDatePicker.type';
import { timestampToDayjs } from 'utils/helpers/date.helpers';
import { translate } from 'locales/i18n';
import dayjs from 'dayjs';

function CustomDatePicker({
  name,
  label,
  value,
  error,
  required,
  placeholder,
  size,
  disabled,
  onChange,
  valueOptions,
  clearDateButton,
}: CustomDatePickerProps) {
  return (
    <Stack spacing={1.5}>
      {label && <Typography variant="h5">{translate(label)}</Typography>}
      <LocalizationProvider
        helperText={translate(error?.message || GlobalVariables.EmptyString)}
        dateAdapter={AdapterDayjs}
        fullWidth
        required={required}
        placeholder={placeholder && translate(placeholder)}
        size={size}
        error={!!error}
        name={name}
        InputLabelProps={{
          shrink: true,
        }}
      >
        <DatePicker
          value={value ? dayjs(value as string) : null}
          disabled={disabled}
          onChange={onChange}
          format={GlobalVariables.Date.DefaultFormat}
          slotProps={
            clearDateButton
              ? {
                  textField: {
                    helperText: translate(error?.message || GlobalVariables.EmptyString),
                    error: !!error,
                  },
                  actionBar: {
                    actions: ['clear'],
                  },
                }
              : {
                  textField: {
                    helperText: translate(error?.message || GlobalVariables.EmptyString),
                    error: !!error,
                  },
                }
          }
          maxDate={
            valueOptions && valueOptions.max ? timestampToDayjs(valueOptions.max) : undefined
          }
        />
      </LocalizationProvider>
    </Stack>
  );
}

export default CustomDatePicker;
