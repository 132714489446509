import Paginator from 'types/interfaces/Paginator';
import { GlobalVariables } from 'config/constant';
import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';
import { RootState } from 'redux/store';
import { GetRefreshTokenStore, setLogOut, setRefreshToken } from 'redux/slices/Auth/authSlice';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQuery } from 'redux/api/BaseQueryConfig';
import { Mutex } from 'async-mutex';
import { FilterEnum } from 'types/interfaces/filter.enum';
import { BooleanToDom, ClientStatusEnum } from 'config/enums/common.enums';
import {
  DealStatesEnum,
  ExpandEnum,
  Nullable,
  ProductPriceTypeEnum,
  ProductTypeEnum,
} from 'types/interfaces/sharedType.type';
import { isArray } from 'lodash';

export function injectFilter<T>(propertyName: FilterEnum, propertyValue: T | null | undefined) {
  if (!propertyValue) return {};
  return {
    [propertyName]: propertyValue,
  };
}
function injectArrayFilter<T>(arrayToInject: T[] | null | undefined, propertyName: FilterEnum) {
  if (!arrayToInject || arrayToInject.length === 0) return undefined;
  const array: Record<string, T>[] = [];
  arrayToInject.map((item) => array.push(injectFilter<T>(propertyName, item)));
  return { [propertyName]: array };
}
export const paginatorToApiPagination = (paginator: Paginator | undefined) => {
  if (paginator) {
    return {
      ...paginator,
      itemsPerPage: paginator.rowsPerPage,
      order: paginator.order,
      keyword: paginator.searchText,
    };
  }
  return {};
};
export const injectFilterStatus = (query: boolean | null) => {
  if (query === null) return undefined;
  const result = query ? GlobalVariables.STATUS.ACTIVE : GlobalVariables.STATUS.INACTIVE;
  return injectFilter(FilterEnum.Status, result);
};
export const injectActivationStatusClientFilter = (query: boolean | null) => {
  if (query === null) return undefined;
  const result = query ? GlobalVariables.STATUS.ACTIVE : GlobalVariables.STATUS.INACTIVE;
  return injectFilter(FilterEnum.ActivationStatus, result);
};
export const injectActivationStatusFilter = (query: string | undefined | null) => {
  if (query === null) return undefined;

  return injectFilter(FilterEnum.Status, query);
};
export const injectFilterDate = (query: number | null | undefined) => {
  return injectFilter<number>(FilterEnum.Date, query);
};

export const injectFilterAgentId = (query: number | null | undefined) => {
  return injectFilter<number>(FilterEnum.AgentId, query);
};
export const injectFilterOriginOrderId = (query: number | null | undefined) => {
  return injectFilter<number>(FilterEnum.OriginOrderId, query);
};
export const injectFilterProfileId = (query: number | null | undefined) => {
  return injectFilter<number>(FilterEnum.Profile, query);
};
export const injectFilterClientId = (query: number | null | undefined) => {
  return injectFilter<number>(FilterEnum.ClientId, query);
};
export const injectFilterUserId = (query: Nullable<number>) =>
  injectFilter<number>(FilterEnum.UserId, query);
export const injectFilterHasSupportArticle = (query: 'true' | 'false' | null | undefined) =>
  injectFilter<'true' | 'false'>(FilterEnum.HasSupport, query);

export const injectFilterHasDiscountArticle = (query: 'true' | 'false' | null | undefined) =>
  injectFilter<'true' | 'false'>(FilterEnum.HasDiscount, query);

export const injectFilterSectorId = (sectorId: number | null | undefined | number[]) => {
  if (Array.isArray(sectorId)) {
    return injectArrayFilter<number>(sectorId, FilterEnum.Sector);
  }
  return injectFilter<number>(FilterEnum.Sector, sectorId);
};
export const injectFilterClientCategoryId = (clientCategoryId: number | null | undefined) =>
  injectFilter<number>(FilterEnum.ClientCategory, clientCategoryId);

export const injectFilterClientCategoryIdArray = (clientCategoryId: number[] | null | undefined) =>
  injectArrayFilter<number>(clientCategoryId, FilterEnum.CategoryId);

export const injectFilterClientClassId = (clientClassId: number | null | undefined) =>
  injectFilter<number>(FilterEnum.ClientClass, clientClassId);

export const injectFilterClientClassArray = (clientClassId: number[] | null | undefined) => {
  if (Array.isArray(clientClassId)) {
    return injectArrayFilter<number>(clientClassId, FilterEnum.ClassId);
  }
  return injectFilter<number>(FilterEnum.ClassId, clientClassId);
};
export const injectFilterCategoryId = (categoryId: number | null | undefined) => {
  return injectFilter<number>(FilterEnum.Category, categoryId);
};
export const injectFilterZoneId = (zoneId: number | null | undefined) =>
  injectFilter<number>(FilterEnum.Zone, zoneId);

export const injectFilterProductType = (productType: ProductTypeEnum | null | undefined) =>
  injectFilter<ProductTypeEnum>(FilterEnum.ProductType, productType);

export const injectZoneChiefId = (zoneChiefId: number | null | undefined) => {
  return injectFilter<number>(FilterEnum.ZoneChief, zoneChiefId);
};
export const injectFilterProductPriceType = (
  productPriceType: ProductPriceTypeEnum | null | undefined,
) => injectFilter<ProductPriceTypeEnum>(FilterEnum.ProductPriceType, productPriceType);

export const injectFilterProductCatalog = (catalogId: number | number[] | null | undefined) =>
  isArray(catalogId)
    ? injectArrayFilter<number>(catalogId, FilterEnum.ProductCatalog)
    : injectFilter<number>(FilterEnum.ProductCatalog, catalogId);

export const injectFilterProductBrand = (brand: number | null | undefined) => {
  return injectFilter<number>(FilterEnum.ProductBrand, brand);
};
export const injectExpand = (expand?: ExpandEnum[] | null) =>
  injectArrayFilter<ExpandEnum>(expand, FilterEnum.Expand);
export const injectDiffs = (value?: BooleanToDom | null) => injectFilter(FilterEnum.Diffs, value);
export const injectFilterWarehouseId = (warehouseId: number | null | undefined) =>
  injectFilter<number>(FilterEnum.WarehouseId, warehouseId);
export const injectFilterSalesLineId = (salesLineId: number | null | undefined) =>
  injectFilter<number>(FilterEnum.SalesLineId, salesLineId);
export const injectClientStatusFilter = (query: ClientStatusEnum | undefined | null) =>
  injectFilter<ClientStatusEnum>(FilterEnum.ClientStatus, query);
export const injectDealStatesFilter = (query: DealStatesEnum[] | undefined | null) =>
  injectArrayFilter<DealStatesEnum>(query, FilterEnum.DealStates);
export const injectExcludedDiscountsFilter = (query: Nullable<number[]>) =>
  injectArrayFilter<number>(query, FilterEnum.ExcludedDiscounts);
export async function handleRefreshToken(
  result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>,
  api: BaseQueryApi,
  extraOptions: Record<string, unknown>,
  args: string | FetchArgs,
  mutex: Mutex,
) {
  const fetchWithBaseQuery = fetchBaseQuery(baseQuery);

  const release = await mutex.acquire();
  try {
    const state = api.getState() as RootState;
    const refreshToken = GetRefreshTokenStore(state);

    const refreshResult = await fetchWithBaseQuery(
      {
        method: 'POST',
        url: ENDPOINTS.REFRESH_TOKEN,
        body: { refreshToken },
      },
      api,
      extraOptions,
    );
    if (refreshResult.data) {
      api.dispatch(
        setRefreshToken({
          token: (refreshResult.data as { accessToken: string }).accessToken,
        }),
      );
      // retry the initial query
      result = await fetchWithBaseQuery(args, api, extraOptions);
    } else {
      api.dispatch(setLogOut());
    }
  } finally {
    // release must be called once the mutex should be released again.
    release();
  }
  return result;
}
