import {
  IHomeSectionsData,
  IHomeSectionsResultApi,
  IHomeSectionsSchemaResponse,
  IHomeSectionsSchemaResponseApi,
} from 'types/models/HomeSections/HomeSections.schema';
import { transformItemArticleResponse } from 'redux/api/article/articlesApi.transform';

export const transformHomeSectionsResponse = (
  response: IHomeSectionsSchemaResponseApi,
): IHomeSectionsSchemaResponse => {
  return {
    lastPage: response?.lastPage,
    currentPage: response?.currentPage,
    previousPage: response?.previousPage,
    nextPage: response?.nextPage,
    total: response?.total,
    totalPerPage: response?.totalPerPage,
    data: response?.results?.map((item) => transformItemHomeSectionsResult(item)),
  };
};

export const transformItemHomeSectionsResult = (
  item: IHomeSectionsResultApi,
): IHomeSectionsData => {
  return {
    id: item?.id,
    zoneId: item?.zoneId,
    clientCategoryId: item?.clientCategoryId,
    color: item?.color,
    titleFr: item?.titleFr,
    titleAr: item?.titleAr,
    title: item?.title,
    updatedAt: item?.updatedAt,
    order: item?.order,
    link: {
      type: item?.link?.type,
    },
    articles: item?.articles?.map((article) => transformItemArticleResponse(article)),
  };
};
