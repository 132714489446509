import React, { useEffect, useMemo } from 'react';
import { Alert, AlertTitle, Grow, GrowProps, Snackbar, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetSnackConfig, resetSnackBarConfig } from 'redux/slices/app/appSlice';
import { GlobalVariables } from 'config/constant';

function GrowTransition(props: GrowProps) {
  return <Grow {...props} />;
}

export function CustomSnack() {
  const dispatch = useDispatch();
  const snackConfig = useSelector(GetSnackConfig);
  const handleClose = () => {
    dispatch(resetSnackBarConfig());
  };
  const { t } = useTranslation();
  const durationOfSnack = useMemo(() => {
    // 15 characters
    if (snackConfig?.message && snackConfig?.message?.length > 15)
      return GlobalVariables.Alerts.LongDuration;
    return GlobalVariables.Alerts.DefaultDuration;
  }, [snackConfig.message]);
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (snackConfig.open) {
      timeoutId = setTimeout(() => {
        handleClose();
      }, durationOfSnack);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [snackConfig.open]);
  return (
    <Snackbar
      autoHideDuration={durationOfSnack}
      open={snackConfig.open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      TransitionComponent={GrowTransition}
    >
      <Alert severity={snackConfig.severity} onClose={handleClose}>
        <AlertTitle>{t(snackConfig.title ?? '')}</AlertTitle>
        <Typography variant="body2">{t(snackConfig.message ?? '')}</Typography>
      </Alert>
    </Snackbar>
  );
}
